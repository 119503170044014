import { Col, Row, Container, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import {
  SobreMaisTopPage,
  BannerInicial,
  ContainerInformacoes,
  ContainerDuvidas,
} from "./styles";
import Accordion from "react-bootstrap/Accordion";
import IconSobreMaisTop from "../../images/img/icon-sobre-mais-top.svg";
import images from "../../images/images";
import React from "react";

export function SobreMaisTop({ user }) {
  return (
    <SobreMaisTopPage className="sobre-blackfriday">
      <NavBar user={user} />
      {/* <Container> */}
      <Container>
        <Breadcrumb className="mt-3">
          <Link className="breadcrumb-item" to="/home">
            Home
          </Link>
          <Breadcrumb.Item active>Sobre o +top</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
      <hr className="mt-3" />
      <Row className="mb-4 p-0 m-0">
        <Col
          lg={12}
          className="d-flex justify-content-center mb-5 flex-column p-0 m-0"
        >
          {/* <Container>
            <BannerInicial className="col-12 d-flex">
              <div className="col-12 container-conteudo-banner">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=YtIKCMpN13w"
                  className=""
                >
                  <div className="img-banner">
                    <img src={images.BannerSobreMaisTop} alt="" />
                  </div>
                  <div className="img-banner-mobile">
                    <img src={images.BannerSobreMaisTopMobile} alt="" />
                  </div>
                </a>
              </div>
            </BannerInicial>
          </Container> */}
          <ContainerInformacoes className="container-informacoes">
            <Container className="d-flex flex-wrap container-inf">
              <div className="container-left col-12 col-lg-6 pt-5">
                {user.cargo.descricao === "VENDEDOR" ? (
                  <div className="col-12 col-lg-9">
                    <h2>Olá, vendedor(a), conheça o +top!</h2>
                    <img
                      className="tomas-sobre-mobile col-12"
                      src={images.TomasSobreMaisTopMobile}
                      alt=""
                    />
                    <p className="mt-5">
                      Aqui, seu foco, sua determinação e suas vendas de produtos
                      Brastemp e Consul participantes valem muitos pontos e
                      realizam sonhos.
                      <br />
                      <br />E você, pode ganhar pontos de diversas formas!
                    </p>
                    <strong>
                      <p className="mt-5">E o melhor:</p>
                    </strong>
                    <img
                      className="mt-1 col-12"
                      src={IconSobreMaisTop}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}

                {user.cargo.descricao === "GESTOR DA INFORMACAO" ? (
                  <div className="col-12 col-lg-9">
                    <h2>Olá, gestor da informação, conheça o +top!</h2>
                    <img
                      className="tomas-sobre-mobile col-12"
                      src={images.TomasSobreMaisTopMobile}
                      alt=""
                    />
                    <p className="mt-5">
                      Aqui, seu foco e determinação valem muitos pontos e
                      realizam sonhos.
                      <br />
                      <br />E você, pode ganhar pontos de diversas formas!
                    </p>
                    <strong>
                      <p className="mt-5">E o melhor:</p>
                    </strong>
                    <img
                      className="mt-1 col-12 mb-4"
                      src={IconSobreMaisTop}
                      alt=""
                    />
                    <p>
                      Você tem um papel fundamental no programa, pois todos da
                      revenda dependem da sua ação para terem os dados
                      atualizados e receberem as pontuações de vendas do mês!
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {user.cargo.descricao === "GERENTE" ? (
                  <div className="col-12 col-lg-9">
                    <h2>Olá, gerente de loja, conheça o +top!</h2>
                    <img
                      className="tomas-sobre-mobile col-12"
                      src={images.TomasSobreMaisTopMobile}
                      alt=""
                    />
                    <p className="mt-5">
                      Aqui, seu foco, sua determinação e as vendas de produtos
                      Brastemp e Consul participantes da sua equipe valem muitos
                      pontos e realizam sonhos.
                      <br />
                      <br />E você, pode ganhar pontos de diversas formas!
                    </p>
                    <strong>
                      <p className="mt-5">E o melhor:</p>
                    </strong>
                    <img
                      className="mt-1 col-12 mb-4"
                      src={IconSobreMaisTop}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}

                {user.cargo.descricao === "GERENTE REGIONAL" ? (
                  <div className="col-12 col-lg-9">
                    <h2>Olá, gerente regional, conheça o +top!</h2>
                    <img
                      className="tomas-sobre-mobile col-12"
                      src={images.TomasSobreMaisTopMobile}
                      alt=""
                    />
                    <p className="mt-5">
                      Aqui, seu foco, sua determinação e as vendas de produtos
                      Brastemp e Consul participantes da sua equipe valem muitos
                      pontos e realizam sonhos. E você, pode ganhar pontos de
                      diversas formas!
                    </p>
                    <strong>
                      <p className="mt-5">E o melhor:</p>
                    </strong>
                    <img
                      className="mt-1 col-12 mb-4"
                      src={IconSobreMaisTop}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
                {user.cargo.descricao === "DIRETOR" ? (
                  <div className="col-12 col-lg-9">
                    <h2>
                      Olá, Diteror,
                      <br />
                      conheça o +top!
                    </h2>
                    <img
                      className="tomas-sobre-mobile col-12"
                      src={images.TomasSobreMaisTopMobile}
                      alt=""
                    />
                    <p className="mt-5">
                      Aqui, seu foco, sua determinação e as vendas de produtos
                      Brastemp e Consul participantes da sua equipe valem muitos
                      pontos e realizam sonhos. E você, pode ganhar pontos de
                      diversas formas!
                    </p>
                    <strong>
                      <p className="mt-5">E o melhor:</p>
                    </strong>
                    <img
                      className="mt-1 col-12 mb-4"
                      src={IconSobreMaisTop}
                      alt=""
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="container-right col-12 col-lg-6 d-flex justify-content-end">
                {/* <p>E o melhor:</p> */}
                <img
                  className="tomas-sobre-desk"
                  src={images.TomasSobreMaisTop}
                  alt=""
                />
              </div>
            </Container>
          </ContainerInformacoes>
          <ContainerDuvidas>
            {user.cargo.descricao === "VENDEDOR" ? (
              <Container>
                <p className="titulo-duvidas">
                  <span>Ficou curioso?</span> Saiba tudo sobre o programa
                </p>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Categorias participantes</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Cada revenda possui um mix de categorias participantes,
                      que <strong>varia de 3 a 7 pontos</strong>, e pode, ou
                      não, ser alterada mensalmente.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Produtos SUPERTOPS</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Existem também os <strong>SUPERTOPS</strong>, que são uma
                      seleção de produtos que{" "}
                      <strong>valem muito mais pontos</strong>. Mensalmente os
                      produtos são renovados de acordo com a disponibilidade de
                      cada revenda.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Treinamentos</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Você ainda pode ganhar <strong>pontos extras</strong>{" "}
                        com os treinamentos! Realize os treinamentos
                        identificados com o selo <strong>VALE PONTOS</strong>,
                        dentro do mês vigente em que foram disponibilizados. É
                        necessário obter no mínimo 80% de acerto nas questões.
                        Oferecemos uma grande variedade de temas e produtos.
                        Afinal, conhecimento nunca é demais! 📖
                      </p>
                      <p>
                        <strong>
                          Se você concluir os 2 treinamentos, com no mínimo 80%
                          de acerto nas questões, recebe uma pontuação extra por
                          cada produto participante vendido no mês
                        </strong>
                        , conforme a mecânica vigente da revenda. E o
                        treinamento pode ser refeito quantas vezes você achar
                        necessário dentro do mês vigente.{" "}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Campanhas</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Ao longo do ano temos campanhas extras que{" "}
                        <strong>
                          premiam os melhores desempenhos em vendas.
                        </strong>{" "}
                        Para participar das campanhas é necessário dar o aceite
                        de participação na mecânica durante o período de
                        vigência.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Convide um amigo</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Sabia que você pode ganhar pontos trazendo um colega de
                        trabalho para o +top?{" "}
                        <strong>
                          Basta enviar um convite utilizando o seu código
                        </strong>
                        * para seus colegas da área de eletro que ainda não
                        realizaram o cadastro com a gente. São 10 pontos para
                        quem indica e 5 pontos para o indicado.
                      </p>
                      <p>
                        * Código disponível na área “Convide um amigo” do site.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Pontuação por Aniversário</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Para comemorar o seu aniversário, o{" "}
                        <strong>+top te dá 10 pontos de presente</strong>, para
                        você fazer a festa!!
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Aceite mensal</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        E não se esqueça, para receber todos os benefícios do
                        programa, é necessário dar o aceite mensal de
                        participação! Ele é o seu passaporte para a realização
                        dos seus sonhos.
                      </p>
                      <p>
                        O aceite mensal é exibido através de um modal na página
                        inicial do programa.{" "}
                        <strong>
                          Caso o aceite não seja realizado, você não receberá as
                          pontuações do mês
                        </strong>
                        .
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Dica de ouro</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        <strong>
                          Mantenha o seu e-mail e número de celular atualizados
                          na plataforma
                        </strong>{" "}
                        e aceite receber nossas comunicações para saber de tudo
                        em primeira mão!
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item
                    eventKey="0"
                    className="mb-3 border-0 rounded "
                  >
                    <Accordion.Header>
                      <span className="fw-bold">Central de Atendimento</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Ficou com alguma dúvida? Fale com a gente.
                        <br />
                        <br />
                        <strong>Atendimento telefônico:</strong> segunda a
                        sexta-feira (exceto feriados), das 10h às 16h, através
                        do 0800 780 0606.
                      </p>
                      <br />
                      <p>
                        <strong>WhatsApp:</strong> segunda a sexta-feira (exceto
                        feriados), das 09h às 18h através do 0800 780 0606.
                      </p>
                      <br />
                      <p>
                        <strong>Fale Conosco:</strong> segunda a sexta-feira
                        (exceto feriados), das 09h às 18h através do site
                        www.programamaistop.com.br.
                      </p>
                      <br />
                      <p>
                        <strong>E-mail:</strong> segunda a sexta-feira (exceto
                        feriado), das 09h às 18h através do
                        atendimento@programamaistop.com.br.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Container>
            ) : (
              ""
            )}
            {user.cargo.descricao === "GERENTE" ? (
              <Container>
                <Container>
                  <p className="titulo-duvidas">
                    <span>Ficou curioso?</span> Saiba tudo sobre o programa
                  </p>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">
                          Categorias participantes
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Cada revenda possui um mix de categorias participantes,
                        que <strong>varia de 3 a 7 pontos</strong>, e pode, ou
                        não, ser alterada mensalmente.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Produtos SUPERTOPS</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Existem também os <strong>SUPERTOPS</strong>, que são
                          uma seleção de produtos que{" "}
                          <strong>valem muito mais pontos</strong>. Mensalmente
                          os produtos são renovados de acordo com a
                          disponibilidade de cada revenda.
                        </p>
                        <br />
                        <p>
                          E você, recebe 10% da pontuação sobre as vendas e
                          pontuação extra de treinamento da sua equipe que deu o
                          aceite de participação mensal. Quanto melhor sua
                          equipe performar, mais você ganha!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Treinamentos</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Você não ganha pela realização dos treinamentos, mas
                          ganha em cima do desempenho da sua equipe. Quando sua
                          equipe realiza os treinamentos, recebem mais pontos em
                          cima das vendas, e você também!
                        </p>
                        <p>
                          Se o vendedor concluir os 2 treinamentos com no mínimo
                          80% de acerto nas questões, recebe uma pontuação extra
                          por cada produto participante vendido no mês, conforme
                          a mecânica vigente da revenda.
                        </p>
                        <p>
                          E o treinamento pode ser refeito quantas vezes o
                          vendedor achar necessário dentro do mês vigente.{" "}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Campanhas</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ao longo do ano temos campanhas extras que{" "}
                          <strong>
                            premiam os melhores desempenhos em vendas.
                          </strong>{" "}
                          Para participar das campanhas é necessário dar o
                          aceite de participação na mecânica durante o período
                          de vigência.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Convide um amigo</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Sabia que você pode ganhar pontos trazendo um colega
                          de trabalho para o +top?{" "}
                          <strong>
                            Basta enviar um convite utilizando o seu código
                          </strong>
                          * para seus colegas da área de eletro que ainda não
                          realizaram o cadastro com a gente. São 10 pontos para
                          quem indica e 5 pontos para o indicado.
                        </p>
                        <p>
                          * Código disponível na área “Convide um amigo” do
                          site.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">
                          Pontuação por Aniversário
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Para comemorar o seu aniversário, o{" "}
                          <strong>+top te dá 10 pontos de presente</strong>,
                          para você fazer a festa!!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Aceite mensal</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          E não se esqueça, para receber todos os benefícios do
                          programa, é necessário dar o aceite mensal de
                          participação! Ele é o seu passaporte para a realização
                          dos seus sonhos.
                        </p>
                        <p>
                          O aceite mensal é exibido através de um modal na
                          página inicial do programa.{" "}
                          <strong>
                            Caso o aceite não seja realizado, você não receberá
                            as pontuações do mês
                          </strong>
                          .
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Dica de ouro</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <strong>
                            Mantenha o seu e-mail e número de celular
                            atualizados na plataforma
                          </strong>{" "}
                          e aceite receber nossas comunicações para saber de
                          tudo em primeira mão!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Central de Atendimento</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ficou com alguma dúvida? Fale com a gente.
                          <br />
                          <br />
                          <strong>Atendimento telefônico:</strong> segunda a
                          sexta-feira (exceto feriados), das 10h às 16h, através
                          do 0800 780 0606.
                        </p>
                        <br />
                        <p>
                          <strong>WhatsApp:</strong> segunda a sexta-feira
                          (exceto feriados), das 09h às 18h através do 0800 780
                          0606.
                        </p>
                        <br />
                        <p>
                          <strong>Fale Conosco:</strong> segunda a sexta-feira
                          (exceto feriados), das 09h às 18h através do site
                          www.programamaistop.com.br.
                        </p>
                        <br />
                        <p>
                          <strong>E-mail:</strong> segunda a sexta-feira (exceto
                          feriado), das 09h às 18h através do
                          atendimento@programamaistop.com.br.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Container>
              </Container>
            ) : (
              ""
            )}

            {user.cargo.descricao === "GERENTE REGIONAL" ||
            user.cargo.descricao === "DIRETOR" ? (
              <Container>
                <Container>
                  <p className="titulo-duvidas">
                    <span>Ficou curioso?</span> Saiba tudo sobre o programa
                  </p>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">
                          Categorias participantes
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Cada revenda possui um mix de categorias participantes,
                        que <strong>varia de 3 a 7 pontos</strong>, e pode, ou
                        não, ser alterada mensalmente.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Produtos SUPERTOPS</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Existem também os <strong>SUPERTOPS</strong>, que são
                          uma seleção de produtos que{" "}
                          <strong>valem muito mais pontos</strong>. Mensalmente
                          os produtos são renovados de acordo com a
                          disponibilidade de cada revenda.
                        </p>
                        <br />
                        <p>
                          E você, recebe 5% da pontuação sobre as vendas e
                          pontuação extra de treinamento da sua equipe que deu o
                          aceite de participação mensal. Quanto melhor sua
                          equipe performar, mais você ganha!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Treinamentos</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Você não ganha pela realização dos treinamentos, mas
                          ganha em cima do desempenho da sua equipe. Quando sua
                          equipe realiza os treinamentos, recebem mais pontos em
                          cima das vendas, e você também!
                        </p>
                        <p>
                          Se o vendedor concluir os 2 treinamentos com no mínimo
                          80% de acerto nas questões, recebe uma pontuação extra
                          por cada produto participante vendido no mês, conforme
                          a mecânica vigente da revenda.
                        </p>
                        <p>
                          E o treinamento pode ser refeito quantas vezes o
                          vendedor achar necessário dentro do mês vigente.{" "}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Campanhas</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ao longo do ano temos campanhas extras que{" "}
                          <strong>
                            premiam os melhores desempenhos em vendas.
                          </strong>{" "}
                          Para participar das campanhas é necessário dar o
                          aceite de participação na mecânica durante o período
                          de vigência.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Convide um amigo</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Sabia que você pode ganhar pontos trazendo um colega
                          de trabalho para o +top?{" "}
                          <strong>
                            Basta enviar um convite utilizando o seu código
                          </strong>
                          * para seus colegas da área de eletro que ainda não
                          realizaram o cadastro com a gente. São 10 pontos para
                          quem indica e 5 pontos para o indicado.
                        </p>
                        <p>
                          * Código disponível na área “Convide um amigo” do
                          site.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">
                          Pontuação por Aniversário
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Para comemorar o seu aniversário, o{" "}
                          <strong>+top te dá 10 pontos de presente</strong>,
                          para você fazer a festa!!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Aceite mensal</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          E não se esqueça, para receber todos os benefícios do
                          programa, é necessário dar o aceite mensal de
                          participação! Ele é o seu passaporte para a realização
                          dos seus sonhos.
                        </p>
                        <p>
                          O aceite mensal é exibido através de um modal na
                          página inicial do programa.{" "}
                          <strong>
                            Caso o aceite não seja realizado, você não receberá
                            as pontuações do mês
                          </strong>
                          .
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Dica de ouro</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <strong>
                            Mantenha o seu e-mail e número de celular
                            atualizados na plataforma
                          </strong>{" "}
                          e aceite receber nossas comunicações para saber de
                          tudo em primeira mão!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Central de Atendimento</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ficou com alguma dúvida? Fale com a gente.
                          <br />
                          <br />
                          <strong>Atendimento telefônico:</strong> segunda a
                          sexta-feira (exceto feriados), das 10h às 16h, através
                          do 0800 780 0606.
                        </p>
                        <br />
                        <p>
                          <strong>WhatsApp:</strong> segunda a sexta-feira
                          (exceto feriados), das 09h às 18h através do 0800 780
                          0606.
                        </p>
                        <br />
                        <p>
                          <strong>Fale Conosco:</strong> segunda a sexta-feira
                          (exceto feriados), das 09h às 18h através do site
                          www.programamaistop.com.br.
                        </p>
                        <br />
                        <p>
                          <strong>E-mail:</strong> segunda a sexta-feira (exceto
                          feriado), das 09h às 18h através do
                          atendimento@programamaistop.com.br.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Container>
              </Container>
            ) : (
              ""
            )}

            {user.cargo.descricao === "GESTOR DA INFORMACAO" ? (
              <Container>
                <Container>
                  <p className="titulo-duvidas">
                    <span>Ficou curioso?</span> Saiba tudo sobre o programa
                  </p>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Envio de bases</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        Envie as bases de funcionários e vendas com 100% de
                        sucesso até o 5º dia útil de cada mês e receba 300
                        pontos.{" "}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Bônus trimestral</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Faça o envio das bases de funcionários e vendas dentro
                          do prazo e com 100% de sucesso, por 3 meses
                          consecutivos, e receba mais 300 pontos extras.
                        </p>
                        <br />
                        <p>
                          Caso a publicação das planilhas não ocorra até o dia
                          15 do mês, as bases só poderão ser enviadas no mês
                          seguinte. Caso isso ocorra, todos da revenda ficaram
                          sem as pontuações de vendas do mês 😞
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Campanhas</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ao longo do ano temos campanhas extras que{" "}
                          <strong>
                            premiam os melhores desempenhos em vendas.
                          </strong>{" "}
                          Para participar das campanhas é necessário dar o
                          aceite de participação na mecânica durante o período
                          de vigência.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">
                          Pontuação por Aniversário
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Para comemorar o seu aniversário, o{" "}
                          <strong>+top te dá 10 pontos de presente</strong>,
                          para você fazer a festa!!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Aceite mensal</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          E não se esqueça, para receber todos os benefícios do
                          programa, é necessário dar o aceite mensal de
                          participação! Ele é o seu passaporte para a realização
                          dos seus sonhos.
                        </p>
                        <p>
                          O aceite mensal é exibido através de um modal na
                          página inicial do programa.{" "}
                          <strong>
                            Caso o aceite não seja realizado, você não receberá
                            as pontuações do mês
                          </strong>
                          .
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Dica de ouro</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <strong>
                            Mantenha o seu e-mail e número de celular
                            atualizados na plataforma
                          </strong>{" "}
                          e aceite receber nossas comunicações para saber de
                          tudo em primeira mão!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion>
                    <Accordion.Item
                      eventKey="0"
                      className="mb-3 border-0 rounded "
                    >
                      <Accordion.Header>
                        <span className="fw-bold">Central de Atendimento</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Ficou com alguma dúvida? Fale com a gente.
                          <br />
                          <br />
                          <strong>Atendimento telefônico:</strong> segunda a
                          sexta-feira (exceto feriados), das 10h às 16h, através
                          do 0800 780 0606.
                        </p>
                        <br />
                        <p>
                          <strong>Fale Conosco:</strong> segunda a sexta-feira
                          (exceto feriados), das 09h às 18h através do site
                          www.programamaistop.com.br.
                        </p>
                        <br />
                        <p>
                          Você também possui canais exclusivos de atendimento
                          com a gente.
                        </p>
                        <p>
                          <strong>WhatsApp exclusivo :</strong> segunda a
                          sexta-feira (exceto feriados), das 09h às 18h através
                          do (11) 94941-4998.
                        </p>
                        <br />
                        <p>
                          <strong>E-mail:</strong>segunda a sexta-feira (exceto
                          feriado), das 09h às 18h através do
                          atendimentogi@programamaistop.com.br.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Container>
              </Container>
            ) : (
              ""
            )}
          </ContainerDuvidas>
        </Col>
      </Row>
      {/* </Container> */}
      <Footer />
    </SobreMaisTopPage>
  );
}

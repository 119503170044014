import { createGlobalStyle } from "styled-components";

/*BlackFriday tema */
export const GlobalStyleTheme = createGlobalStyle`

.navbar-itens{
    .nav-link{
            color: #fff!important;
    }
}
.footer-component{
    .nav-link{
            color: #fff!important;
    }
}

.tom-mais-top{

    @media (max-width: 1900px) {
            width: 130% !important;
            margin-left: 12vw!important;
        }
}
.container-conteudo-home{

    margin-top: 108px!important;
    
}


.footer-component {
    position: relative !important;
    overflow: hidden;

    @media (max-width: 730px) {
        overflow-x: hidden !important;
        height: 234px !important;
    }

    .bg-footer-tema {
        position: absolute !important;
        width: 100% !important;
        top:0;
        height: 153px;

        @media (max-width: 730px) {
            height: 233px !important;
            width: 321% !important;
            display: none;
        }

        @media (max-width: 1500px) {
            position: absolute !important;
            width: 101% !important;
            height: 148px !important;
            top:0;
        }

    }

    .bg-footer-tema-mobile {
        display: none;

        @media (max-width: 730px) {
            /* height: 233px !important; */
            width: 100% !important;
            display: block;
        }
    }

    .footer {
        background-color: transparent !important;
    }
}

.bg-nav-tema {
    position: absolute !important;
    width: 100% !important;
    height:92px;
    @media (max-width: 730px) {
            height: 66px;
        }
}


.container-enfeite-nav-tema {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    .enfeite-nav-tema {
        margin-top: -120px;
        z-index: -1;
    }
}

.enfeite-nav-tema-mobile {
    height: 9vh;
    z-index: 12;
    margin-top: -30px !important;
}
}

.login-tema {
    .enfeite-bolas-natal {
        position: absolute;
        top: -418px;
        // width: 305px;
        right:-370px;
        @media (max-width: 720px) {
            display: none;
        }
        @media (max-width: 1900px) {
            top: -420px;
            left: 339px;
        }
    }
}


.bg-desktop {
    @media (max-width: 720px) {
        padding-left: 0 !important;
        
    }
}

.bg-mobile-natal {
    position: absolute;
    width: 105vw;
    height: 105vh;
    top: -41%;

    @media (min-width: 720px) {
        display: none;
    }
}

.logo-tema-natal {
    width: 56%;
    z-index: 4;
    background-color: white;
    border-radius: 13px;
    padding-right: 30px;
    padding-left: 29px;
    padding-bottom: 6px;
    padding-top: 70px;
    position: absolute;
    top: -87px;
}

.enfeite-mobile-natal {
    z-index: 4;
    position: absolute;
    width: 107px;
    right: -8px;
    top: -35px;
}

.cadastro-loja-tema {
    .footer-component {
        height: 172px;
    }
}

.extrato-tema {
    min-height: 50rem !important;

    .footer-component {
        height: 172px;
    }

    .container-extrato {
        height: 711px;
    }
}

`;

import { Breadcrumb, Button, Col, Container, Form, Row, Fade,Card } from "react-bootstrap";
import { NavBar } from "../../components/NavBar/index";
import { Footer } from "../../components/Footer/index";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Home, FiltroExtrato, TresColunas } from "./styles";
import React, { useEffect, useState } from "react";
import { rotasApi } from "../../services/rotasApi";
import api from "../../services/api";

export function Extrato({ user }) {
  const [extratoGeral, setExtratoGeral] = useState(true);
  const [vendasRealizadas, setVendasRealizadas] = useState(false);
  const [creditos, setCreditos] = useState(false);
  const [debitos, setDebitos] = useState(false);
  const [modalErroCatalogo, setModalErroCatalogo] = useState(false);
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [modalMsg, setModalMsg] = useState("");

  /* const [creditoExtratoGeral, setCreditoExtratoGeral] = useState();
   const [debitoExtratoGeral, setDebitoExtratoGeral] = useState();
   const [pontosExpiradosExtratoGeral, setExpiradosExtratoGeral] = useState();
   const [dataUltimoCreditoExtratoGeral, setdataUltimoCreditoExtratoGeral] =
     useState();
   const [pontosAExpirarExtratoGeral, setPontosAExpirarExtratoGeral] =
     useState();*/
  //const [saldoGeral, setSaldoGeral] = useState();

  const [erroExtrato, setErroExtrato] = useState();
  const [erroResgates, setErroResgates] = useState();
  const [erroExpiracao, setErroExpiracao] = useState();
  const [erroVendas, setErroVendas] = useState();

  const [extratoGeralLista, setExtratoGeralLista] = useState([]);
  const [extratoGeralTotal, setExtratoGeralTotal] = useState();

  const [vendasRealizadasLista, setVendasRealizadasLista] = useState([]);
  const [vendasRealizadasTotal, setVendasRealizadasTotal] = useState();

  const [resgatesLista, setResgatesLista] = useState([]);
  const [resgatesTotal, setResgatesTotal] = useState();

  const [expiracaoPontosLista, setExpiracaoPontosLista] = useState([]);
  //const [expiracaoPontosTotal, setExpiracaoPontosTotal] = useState();

  const [saldoShow, setSaldoShow] = useState(false);
  const [saldoValor, setSaldoValor] = useState(0);

  const [changeEye, setChangeEye] = useState("bi bi-eye-slash icon-large");

  const toggleSaldo = () => {
    setSaldoShow(!saldoShow);
    saldoShow
      ? setChangeEye("bi bi-eye-slash icon-large")
      : setChangeEye("bi bi-eye icon-large");
  };

  var today = new Date();
  //var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1);
  var yyyy = today.getFullYear();
  var anos = [];
  var anoInicio = 2022;
  for (anoInicio; anoInicio <= yyyy; anoInicio++) {
    anos.push({
      ano: anoInicio,
    });
  }

  const [selectAnoExtrato, setSelectAnoExtrato] = useState(yyyy);
  const [selectAnoVendas, setSelectAnoVendas] = useState(yyyy);
  const [selectAnoResgates, setSelectAnoResgates] = useState(yyyy);
  const [selectAnoExpiracao, setSelectAnoExpiracao] = useState(yyyy);

  var meses = [
    { valMes: 1, mes: "Janeiro" },
    { valMes: 2, mes: "Fevereiro" },
    { valMes: 3, mes: "Março" },
    { valMes: 4, mes: "Abril" },
    { valMes: 5, mes: "Maio" },
    { valMes: 6, mes: "Junho" },
    { valMes: 7, mes: "Julho" },
    { valMes: 8, mes: "Agosto" },
    { valMes: 9, mes: "Setembro" },
    { valMes: 10, mes: "Outubro" },
    { valMes: 11, mes: "Novembro" },
    { valMes: 12, mes: "Dezembro" },
  ];

  const abreAbaPremios = (e) => {
    if (sessionStorage.getItem("acessoEspelho") === 'true') {
      setModalErro(true);
      setModalMensagem('Catálogo de premios não pode ser acessado do acesso espelho.');
      setTimeout(() => {
        setModalErro(false);
      }, 3000);
    } else {
      api
        .get(rotasApi.linkCatalogoPremios)
        .then((result) => {
          const { mensagem, url } = result.data;
          if (url.length > 0) {
            window.open(`${url}`, "_blank");
            return;
          }
          setModalErroCatalogo(true);
          setModalMsg(mensagem);
          setTimeout(() => {
            setModalErroCatalogo(false);
          }, 3000);
        })
        .catch((error) => {
          console.log(error);
        });
      // setModalErro(true);
      // setModalMensagem('Catálogo de prêmios está temporariamente fora do ar devido a manutenção.');
      // setTimeout(() => {
      //   setModalErro(false);
      // }, 3000);
    }
  };

  useEffect(() => {
    let mesAno = {
      mes: mm,
      ano: yyyy,
    };
    api
      .post(rotasApi.saldo + `?usuarioId=${user.id}`)
      .then((result) => {
        if (result.data !== null && ('saldo' in result.data)) {
          setSaldoValor(result.data.saldo);
        } else {
          setSaldoValor(0);
          setModalErro(true);
          setModalMensagem(result.data.mensagem);
          setTimeout(() => {
            setModalErro(false);
          }, 5000);
        }
      })
      .catch((error) => {
        setSaldoValor(0);
        console.log("error", error.response);
      });

    ExtratoGeralFiltrar(mesAno);
    if (user.cargo.descricao === "VENDEDOR") {
      VendasRealizadasFiltrar(mesAno);
      mostraVendasRealizadas()
    }
    ResgatesFiltrar(mesAno);
    PontosFiltrar(mesAno);
  }, [mm, yyyy, user]);

  const ExtratoGeralFiltrar = (values) => {

    api
      .get(rotasApi.extratoGeral + `?Mes=${values.mes}&Ano=${values.ano}`)
      .then((result) => {

        let newArray = [];
        result.data.extrato.map(
          ({
            dataCadastro,
            descricao,
            pontuacao,
            tipoOperacao,
            anoVigente,
            mesVigente,
          }) =>
            newArray.push({
              dataCadastro: dataCadastro,
              descricao: descricao,
              pontuacao: Number(pontuacao.replace(",", ".")),
              tipoOperacao: tipoOperacao,
              anoVigente: anoVigente,
              mesVigente: mesVigente,
            })
        );

        result.data.extrato = newArray;
        // result.data.extrato.map(({ pontuacao }) => (total = total + pontuacao));
        setExtratoGeralTotal(result.data.total);
        setErroExtrato("");
        setExtratoGeralLista(result.data.extrato);
      })
      .catch((error) => {
        console.log("error > ", error.response);
        if (error.response.status === 400) {
          setErroExtrato("Não foi possível exibir o extrato");
        } else {
          setErroExtrato(error.response.data.mensagem);
        }
      });
  };

  const VendasRealizadasFiltrar = (values) => {

    api
      .get(
        rotasApi.extratoVendasRealizadas +
        `?Mes=${values.mes}&Ano=${values.ano}`
      )
      .then((result) => {
        let total = 0;
        let newArray = [];
        result.data.vendas.map(({ data, descricao, pontos, quantidade }) =>
          newArray.push({
            data: data,
            descricao: descricao,
            pontos: Number(pontos.replace(",", ".")),
            quantidade: quantidade,
          })
        );

        result.data.vendas = newArray;

        result.data.vendas.map(({ pontos }) => (total = total + pontos));
        setVendasRealizadasTotal(total.toFixed(2));
        setErroVendas("");
        setVendasRealizadasLista(result.data.vendas);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setErroVendas("Não foi possível exibir as vendas");
        } else {
          setErroVendas(error.response.data.mensagem);
        }
      });
  };

  const ResgatesFiltrar = (values) => {

    api
      .get(rotasApi.extratoResgates + `?Mes=${values.mes}&Ano=${values.ano}`)
      .then((result) => {

        let total = 0;

        let newArray = [];
        result.data.resgates.map(
          ({ codigo, quantidade, descricao, pontos, data }) =>
            newArray.push({
              codigo: codigo,
              quantidade: quantidade,
              descricao: descricao,
              pontos: Number(pontos.replace(",", ".")),
              data: data,
            })
        );

        result.data.resgates = newArray;

        result.data.resgates.map(({ pontos }) => (total = total + pontos));
        setResgatesTotal(total.toFixed(2));
        setErroResgates("");
        setResgatesLista(result.data.resgates.reverse());
      })
      .catch((error) => {
        setErroResgates(error.response.data.mensagem);
      });
  };

  const PontosFiltrar = (values) => {

    api
      .get(
        rotasApi.extratoPontosExpirados + `?Mes=${values.mes}&Ano=${values.ano}`
      )
      .then((result) => {
        let total = 0;
        result.data.creditos.map(({ pontos }) => (total = total + pontos));
        setExpiracaoPontosLista(result.data.creditos);

        setErroExpiracao("");
      })
      .catch((error) => {
        // setErroExpiracao(error.response.data.mensagem);
      });
  };

  const convertDate = (date) => {
    var convertdate = new Date(date);

    var ddconvertdate = String(convertdate.getDate()).padStart(2, "0");
    var mmconvertdate = String(convertdate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyyconvertdate = String(convertdate.getFullYear()).padStart(4, "0");

    convertdate = ddconvertdate + "/" + mmconvertdate + "/" + yyyyconvertdate;

    return convertdate;
  };

  let [passo, setPasso] = useState(1);

  function mudarPasso(num) {
    setPasso((passo = num));
  }

  const mostraExtratoGeral = () => {
    setExtratoGeral(true);
    setVendasRealizadas(false);
    setCreditos(false);
    setDebitos(false);
    mudarPasso(1);
  };
  const mostraVendasRealizadas = () => {
    setExtratoGeral(false);
    setVendasRealizadas(true);
    setCreditos(false);
    setDebitos(false);
    mudarPasso(2);
  };
  const mostraCreditos = () => {
    setExtratoGeral(false);
    setVendasRealizadas(false);
    setCreditos(true);
    setDebitos(false);
    mudarPasso(3);
  };
  // const mostraDebitos = () => {
  //   setExtratoGeral(false);
  //   setVendasRealizadas(false);
  //   setCreditos(false);
  //   setDebitos(true);
  //   mudarPasso(4);
  // };

  return (
    <>
      <Home className="extrato-blackfriday">
        <NavBar user={user} />
        <Row>
            <Col className="d-flex justify-content-center">
              <Fade in={modalErro}>
                <Card
                  className="text-center p-3 m-2 z-index-top position-absolute"
                  bg="danger"
                  text="white"
                >
                  <Card.Body>
                    <Card.Title as="h2">Erro</Card.Title>
                    <Card.Text>{modalMensagem}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
        <Container >
          <Breadcrumb className="mt-3">
            <Link className="breadcrumb-item" to="/home">
              Home
            </Link>
            <Breadcrumb.Item active>Extrato de pontos</Breadcrumb.Item>
            {passo === 1 ? (
              <Breadcrumb.Item active>Extrato Geral</Breadcrumb.Item>
            ) : passo === 2 ? (
              <Breadcrumb.Item active>Vendas Realizadas</Breadcrumb.Item>
            ) : passo === 3 ? (
              <Breadcrumb.Item active>Resgates</Breadcrumb.Item>
            ) : (
              // : passo === 4 ? (
              //   <Breadcrumb.Item active>Expiração de Pontos</Breadcrumb.Item>
              // )
              ""
            )}
          </Breadcrumb>

          <hr className="mt-3" />
          <Row className="pl-sm-5">
            <Col className="mt-5 mb-4" md="3">
              <FiltroExtrato>
                <div className="box-filtro">
                  <h3 className="h3 desktop">Extrato de pontos</h3>
                  <h3 className="h3 mobile">Extrato de pontos</h3>

                  <div className="filtro-mobile">
                    <span
                      // onClick={mostraExtratoGeral}
                      onClick={abreAbaPremios}
                      className={
                        extratoGeral ? "links-filtro ativo" : "links-filtro"
                      }
                    >
                      Extrato geral
                    </span>

                    {user.cargo.descricao === "VENDEDOR" && (
                      <span
                        onClick={mostraVendasRealizadas}
                        className={
                          vendasRealizadas
                            ? "links-filtro ativo"
                            : "links-filtro"
                        }
                      >
                        Vendas realizadas
                      </span>
                    )}
                    {/* <span
                      onClick={mostraCreditos}
                      className={
                        creditos ? "links-filtro ativo" : "links-filtro"
                      }
                    >
                      Resgates
                    </span> */}
                    {/* <span
                    onClick={mostraDebitos}
                    className={debitos ? "links-filtro ativo" : "links-filtro"}
                  >
                    Expiração de pontos
                  </span> */}
                  </div>
                </div>
                <Row className="m-0">
                  <div className="container-saldo-user mt-5 rounded px-4">
                    <div className="d-flex justify-content-between">
                      <p className="mt-3 mb-0">Pontuação atual</p>
                      <span className="pt-1">
                        <i className={changeEye} onClick={toggleSaldo}></i>
                      </span>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      {saldoShow ? (
                        <span className="fw-bold font-saldo">{saldoValor}</span>
                      ) : (
                        <div className="valor-pontos rounded"></div>
                      )}
                    </div>
                  </div>
                </Row>
              </FiltroExtrato>
            </Col>
            <Col className="mt-5 mb-4" md="9">
              <TresColunas className={extratoGeral ? "d-block" : "d-none"}>
                <div className="box-principal">
                  <h3 className="h3-resultado">Extrato geral<span>{user ? user.nome : ''} - CPF : {user ? user.cpf : ''}</span></h3>

                  <div className="campos-filtro">
                    <p className="frase-filtrar-busca">Buscar até</p>
                    <Formik
                      initialValues={{
                        mes: mm,
                        ano: yyyy,
                      }}
                      onSubmit={(values) => ExtratoGeralFiltrar(values)}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        isValid,
                        handleSubmit,
                        setFieldValue,
                        dirty,
                      }) => (
                        <Form
                          noValidate
                          onSubmit={handleSubmit}
                          className="form-flex"
                        >
                          {selectAnoExtrato === yyyy ? (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(
                                ({ valMes, mes }, index) =>
                                  valMes <= mm && (
                                    <option value={valMes} key={index}>
                                      {mes}
                                    </option>
                                  )
                              )}
                            </Form.Select>
                          ) : (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(({ valMes, mes }, index) => (
                                <option value={valMes} key={index}>
                                  {mes}
                                </option>
                              ))}
                            </Form.Select>
                          )}

                          <Form.Select
                            name="ano"
                            value={values.ano}
                            onChange={(e) => {
                              setFieldValue("ano", e.target.value);
                              setSelectAnoExtrato(Number(e.target.value));
                            }}
                            className="input-mes-ano"
                          >
                            {anos.map(({ ano }, index) => (
                              <option value={ano} key={index}>
                                {ano}
                              </option>
                            ))}
                          </Form.Select>

                          <Button type="submit" className="btn-buscar">
                            Buscar
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="d-lg-none mb-2 pe-4 obs">OBS: Arraste a tabela para os lados para visualizar o conteúdo</div>
                  <div className="tabela-resultado">
                    {erroExtrato ? (
                      <div>
                        <p>{erroExtrato}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-75">Descrição</div>
                          <div className="w-25">Período</div>

                          <div className="w-25">Pontos</div>
                          <div className="w-25">Apurado</div>
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {extratoGeralLista.map(
                              (
                                {
                                  descricao,
                                  pontuacao,
                                  dataCadastro,
                                  anoVigente,
                                  mesVigente,
                                },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-75">{descricao}</div>
                                  <div className="w-25">{`${mesVigente}/${anoVigente}`}</div>

                                  {pontuacao < 0 && (
                                    <div className="w-25 text-danger">
                                      {String(pontuacao).replace(".", ",")}
                                    </div>
                                  )}
                                  {pontuacao > 0 && (
                                    <div className="w-25 text-success ps-2">
                                      {String(pontuacao).replace(".", ",")}
                                    </div>
                                  )}
                                  <div className="w-25">
                                    {convertDate(dataCadastro)}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="rodape-tabela">
                          <div className="w-100">Total do período</div>
                          <div className="w-50 fw-bold">
                            {String(extratoGeralTotal).replace(".", ",")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TresColunas>

              <TresColunas
                id="vendasRealizadas"
                className={vendasRealizadas ? "d-block" : "d-none"}
              >
                <div className="box-principal">
                  <h3 className="h3-resultado">Vendas realizadas</h3>

                  <div className="campos-filtro">
                    <p className="frase-filtrar-busca">Filtrar busca por</p>
                    <Formik
                      initialValues={{
                        mes: mm,
                        ano: yyyy,
                      }}
                      onSubmit={(values) => VendasRealizadasFiltrar(values)}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        isValid,
                        handleSubmit,
                        setFieldValue,
                        dirty,
                      }) => (
                        <Form
                          noValidate
                          onSubmit={handleSubmit}
                          className="form-flex"
                        >
                          {selectAnoVendas === yyyy ? (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(
                                ({ valMes, mes }, index) =>
                                  valMes <= mm && (
                                    <option value={valMes} key={index}>
                                      {mes}
                                    </option>
                                  )
                              )}
                            </Form.Select>
                          ) : (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(({ valMes, mes }, index) => (
                                <option value={valMes} key={index}>
                                  {mes}
                                </option>
                              ))}
                            </Form.Select>
                          )}

                          <Form.Select
                            name="ano"
                            value={values.ano}
                            onChange={(e) => {
                              setFieldValue("ano", e.target.value);
                              setSelectAnoVendas(Number(e.target.value));
                            }}
                            className="input-mes-ano"
                          >
                            {anos.map(({ ano }, index) => (
                              <option value={ano} key={index}>
                                {ano}
                              </option>
                            ))}
                          </Form.Select>

                          <Button type="submit" className="btn-buscar">
                            Filtrar
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="d-lg-none mb-2 pe-4 obs">OBS: Arraste a tabela para os lados para visualizar o conteúdo</div>
                  <div className="tabela-resultado">
                    {erroVendas ? (
                      <div>
                        <p>{erroVendas}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-75">Descrição</div>
                          <div className="w-25">Qtde</div>
                          <div className="w-25">Pontos</div>
                          <div className="w-25">Data</div>
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {vendasRealizadasLista.map(
                              (
                                { data, descricao, pontos, quantidade },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-75">{descricao}</div>
                                  <div className="w-25">{quantidade}</div>
                                  {pontos < 0 && (
                                    <div className="w-25 ps-1 text-danger">
                                      {String(pontos).replace(".", ",")}
                                    </div>
                                  )}
                                  {pontos > 0 && (
                                    <div className="w-25 ps-1 text-success">
                                      {String(pontos).replace(".", ",")}
                                    </div>
                                  )}
                                  <div className="w-25">
                                    {convertDate(data)}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="rodape-tabela">
                          <div className="w-100">Total do período</div>
                          <div className="w-50 fw-bold">
                            {String(vendasRealizadasTotal).replace(".", ",")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TresColunas>

              <TresColunas
                id="creditos"
                className={creditos ? "d-block" : "d-none"}
              >
                <div className="box-principal">
                  <h3 className="h3-resultado">Resgates</h3>

                  <div className="campos-filtro">
                    <p className="frase-filtrar-busca">Filtrar busca por</p>
                    <Formik
                      initialValues={{
                        mes: mm,
                        ano: yyyy,
                      }}
                      onSubmit={(values) => ResgatesFiltrar(values)}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        isValid,
                        handleSubmit,
                        setFieldValue,
                        dirty,
                      }) => (
                        <Form
                          noValidate
                          onSubmit={handleSubmit}
                          className="form-flex"
                        >
                          {selectAnoResgates === yyyy ? (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(
                                ({ valMes, mes }, index) =>
                                  valMes <= mm && (
                                    <option value={valMes} key={index}>
                                      {mes}
                                    </option>
                                  )
                              )}
                            </Form.Select>
                          ) : (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(({ valMes, mes }, index) => (
                                <option value={valMes} key={index}>
                                  {mes}
                                </option>
                              ))}
                            </Form.Select>
                          )}

                          <Form.Select
                            name="ano"
                            value={values.ano}
                            onChange={(e) => {
                              setFieldValue("ano", e.target.value);
                              setSelectAnoResgates(Number(e.target.value));
                            }}
                            className="input-mes-ano"
                          >
                            {anos.map(({ ano }, index) => (
                              <option value={ano} key={index}>
                                {ano}
                              </option>
                            ))}
                          </Form.Select>

                          <Button type="submit" className="btn-buscar">
                            Filtrar
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="d-lg-none mb-2 pe-4 obs">OBS: Arraste a tabela para os lados para visualizar o conteúdo</div>
                  <div className="tabela-resultado">
                    {erroResgates ? (
                      <div>
                        <p>{erroResgates}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-25">N° de pedidos</div>
                          <div className="w-25">quantidade</div>
                          <div className="w-75 ps-4">Descrição</div>
                          <div className="w-25">Pontos</div>
                          <div className="w-25">Data</div>
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {resgatesLista.map(
                              (
                                { codigo, data, descricao, pontos, quantidade },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-25">{codigo}</div>
                                  <div className="w-25">{quantidade}</div>
                                  <div className="w-75 ps-4">{descricao}</div>
                                  <div className="w-25 text-danger">
                                    {"-" + String(pontos).replace(".", ",")}
                                  </div>
                                  <div className="w-25">
                                    {convertDate(data)}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        <div className="rodape-tabela">
                          <div className="w-100">Total do período</div>
                          <div className="w-50 valor-resgates fw-bold">
                            {"-" + String(resgatesTotal).replace(".", ",")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TresColunas>

              <TresColunas
                id="debitos"
                className={debitos ? "d-block" : "d-none"}
              >
                <div className="box-principal">
                  <h3 className="h3-resultado">Expiração de pontos</h3>

                  <div className="campos-filtro">
                    <p className="frase-filtrar-busca">Filtrar busca por</p>
                    <Formik
                      initialValues={{
                        mes: mm,
                        ano: yyyy,
                      }}
                      onSubmit={(values) => PontosFiltrar(values)}
                    >
                      {({
                        values,
                        handleChange,
                        errors,
                        isValid,
                        handleSubmit,
                        setFieldValue,
                        dirty,
                      }) => (
                        <Form
                          noValidate
                          onSubmit={handleSubmit}
                          className="form-flex"
                        >
                          {selectAnoExpiracao === yyyy ? (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(
                                ({ valMes, mes }, index) =>
                                  valMes <= mm && (
                                    <option value={valMes} key={index}>
                                      {mes}
                                    </option>
                                  )
                              )}
                            </Form.Select>
                          ) : (
                            <Form.Select
                              name="mes"
                              value={values.mes}
                              onChange={handleChange}
                              className="input-mes-ano"
                            >
                              {meses.map(({ valMes, mes }, index) => (
                                <option value={valMes} key={index}>
                                  {mes}
                                </option>
                              ))}
                            </Form.Select>
                          )}

                          <Form.Select
                            name="ano"
                            value={values.ano}
                            onChange={(e) => {
                              setFieldValue("ano", e.target.value);
                              setSelectAnoExpiracao(Number(e.target.value));
                            }}
                            className="input-mes-ano"
                          >
                            {anos.map(({ ano }, index) => (
                              <option value={ano} key={index}>
                                {ano}
                              </option>
                            ))}
                          </Form.Select>

                          <Button type="submit" className="btn-buscar">
                            Filtrar
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="d-lg-none mb-2 pe-4 obs">OBS: Arraste a tabela para os lados para visualizar o conteúdo</div>
                  <div className="tabela-resultado">
                    {erroExpiracao ? (
                      <div>
                        <p>{erroExpiracao}</p>
                      </div>
                    ) : (
                      <>
                        <div className="topo-tabela">
                          <div className="w-75">Descrição</div>
                          <div className="w-25">Pontos</div>
                          <div className="w-25">Data</div>
                        </div>
                        <div className="p-1 ps-0 bg-white barra">
                          <div className="corpo-tabela">
                            {expiracaoPontosLista.map(
                              (
                                { quantidade, descricao, pontos, data },
                                index
                              ) => (
                                <div className="linha-tabela" key={index}>
                                  <div className="w-75">{descricao}</div>
                                  <div className="w-25">{pontos}</div>
                                  <div className="w-25">
                                    {convertDate(data)}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TresColunas>
            </Col>
          </Row>
        </Container>
        <Footer className="m-0 p-0"></Footer>
      </Home>

    </>
  );
}

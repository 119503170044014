import styled from "styled-components";

export const Home = styled.div`
	background: #efeeed;
	.info-user-left{
		position: relative;
	}
		.container-conteudo-home{
			height:max-content;
		}
	.banner-slide-mobile{
		@media (max-width: 992px) {
			display: inline;
			margin: 0px 21px;
			.carousel {
				margin: 0px 21px;
			}
			.img-banner{
				height: 400px;
				border-radius: 5px;
			}
		}
		display: none;
	}
	.whatsapp-icon{
		position:fixed;
		width:60px;
		height:60px;
		bottom:40px;
		right:40px;
		background-color:#25d366;
		color:#FFF;
		border-radius:50px;
		text-align:center;
		font-size:30px;
		box-shadow: 1px 1px 2px #888;	
		z-index:1000;
		align-items: center;
		display: flex;
		justify-content: center;
		i {
			 margin-top:7px;
			 margin-left:2px
		}
	}
`;
export const ContainerLeft = styled.div`
	/* background: #fff; */
	margin-right: 20px;
	border-top-left-radius: 5px!important;
	border-top-right-radius: 5px!important;
	.bg-white{
		background: #fff;
	}
	.pd{
		padding-left:25px!important;
		padding-right:25px!important;
		border-top-right-radius: 5px;
		border-top-left-radius: 5px;
	}
	
	.container-conquistas{
		/* border: 1px solid #cdcdcd; */
    	border-radius: 6px;
		padding: 22px;
		background: #fff;
	}
	.ganhou-premio{
		img{
			width: 100%;
		}
	}
	.img-perfil-user {
		display: flex;
		overflow: hidden;
		height: 6rem;
		width: 6rem;
		justify-content: center;
		align-items: center;
		position: relative;
		img {
			width: 65px;
			height: 65px;
		}
		.notificacao-icon{
			right: 0;
			width:21px;
			height:21px;
			background-color: #EF4E23;
			position: absolute;
			cursor: pointer;
			border-radius: 50%;
			top:14px;
			z-index: 4;
			text-align: center;
			p{
				color: #fff;
				margin-top: 2px;
				font-size: 11px;
			}
			border: 2px solid #fff;
		color: white;
		-webkit-box-shadow: -4px -2px 6px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: -4px -2px 6px 0px rgba(0,0,0,0.1);
		box-shadow: -3px 1px 6px 0px rgba(0,0,0,0.1);
		}
		.oculto{
			display: none;
		}
	}
	.nome-perfil-user {
		margin-left: 16px;
		margin-top: 10px;
		h3 {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 10px;
			margin-top: 11px;
			@media (max-width: 992px) {
			font-size: 18px;
			color: #000;
		}
		}
		p {
			font-size: 12px;
			margin-left: 2px;
		}
		span {
			color: #9ccb3d;
		}
	}
	.container-saldo-user {
		/* background: #7032d1; */
		color: #fff;
		border: 1px solid #CECDD9;
		color: #000;
		/* width: 352px !important; */
    	height: 102px;
		i{
			color: #EF4E23;
		}
		.valor-pontos {
			width: 100%;
			height: 36px;
			/* background: #9a5cfa; */
		}
		.valor-pts {
			font-size: 1rem;
			position: absolute;
			margin-left: 0.5rem;
			margin-top: 0.2rem;
		}
		p {
			font-size: 12px;
			margin-bottom: -4px;
			margin-top: 5px;
		}
		.white-line {
			background-color: white;
			height: 1px;
			width: 10rem;
		}
		.font-saldo {
			font-size: 2rem;
			@media (max-width: 992px) {
			font-size: 24px;
		}
		}
	}
	.container-resgatar-extrato {
		.btn-resgatar,
		.btn-extrato {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			height: 45px;
			text-decoration: none;
			color: white;
			height: 56px;
		}
		.btn-resgatar{
			background: #EF4E23!important;
		}
		.btn-extrato{
			background: none!important;
			color:#EF4E23!important;
			border: 1px solid #EF4E23!important;
		} 
	}
	.btn-convide-amigo {
		height: 45px;
		border-color: #d38f3e;
		color: #f58420;
		padding-top: 10px;
		transition: 0.2s;
		display: none!important;
		&:hover {
			background: #f58420;
			color: #fff;
		}
	}
	.btn-height-3 {
		height: 3rem;
	}
`;
export const Teste = styled.div`
	background: #efeeed;
	.nivel-atual-user,
	.task-card-container {
		position: relative;
		padding: 13px;
		border-radius: 5px;

		.progressBar {
			margin-bottom: 26px;
			margin-bottom: 1.66em;
			position: relative;
		}

		.progressBarcontainer {
			width: 95%;
			height: 10px;
			background: #e2e2e2;
			overflow: hidden;
			border-radius: 5px;
		}

		.progressBarValue {
			height: 10px;
			float: left;
			border-radius: 5px;
			background: #9ed530;
		}

		.speech-bubble {
			font-size: 0.75em;
			line-height: 2em;
			position: absolute;
			top: -0.6em;
			text-align: center;
			min-width: 3em;
			border-radius: 0.3em;
			color: white;
			display: none;
		}

		.speech-bubble:after {
			border: 0.5em solid transparent;
			content: "";
			margin-left: -0.5em;
			position: absolute;
			bottom: -50%;
			left: 50%;
		}

		.html {
			left: calc(88.5% - 1.5em);
		}

		.html:after {
			border-top-color: #a487b2;
		}

		.progress-container {
			position: relative;
		}

		.speech-bubble {
			font-size: 0.75em;
			line-height: 2em;
			position: absolute;
			top: -0.6em;
			text-align: center;
			min-width: 3em;
			border-radius: 0.3em;
			color: white;
			display: none;
		}

		.niveis-progressbar {
			display: flex;
			position: absolute;
			width: 100%;
			justify-content: space-between;
			top: -7px;

			.icone-raking {
				width: 20px;
			}

			p {
				font-size: 12px;
				width: 60px;
				margin-top: 5px;
			}
			
		}


		.title-nivel-user {
			margin-bottom: 12px;
			font-size: 12px;
			@media (max-width: 370px) {
				font-size: 10px;
			}
			// margin-bottom: 16px;
			.nivel-usuario {
				color: #9ed530;
			}
			
		}
		
	}
	.nivel-atual-user {
		background: #fff;
	}
`;
export const Teste2 = styled.div`
	/* display:none; */
	.fundo-branco {
		background: #fff;
	}
	.icon-task {
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		display: grid;
		justify-content: center;
		align-content: center;
		padding: 0;
		margin: 0;
		height: 50px;
		width: 52px;
	}
	.task-section {
		.task-card-container {
			background: #fff;
			margin-top: 18px;
			border: 1px solid #e2e2e2;
			border-radius: 5px;
			padding: 0px;

			.progressBarcontainer {
				width: 100%;
				height: 10px;
				background: #e2e2e2;
				overflow: hidden;
				border-radius: 5px;
				margin-bottom: 6px;
			}

			.progressBarValue {
				height: 10px;
				float: left;
				border-radius: 5px;
				background: #f58420;
			}

			.task-card-conteudo {
				display: flex;
				padding: 15px;

				.task-info {
					height: 60px;
					width: 100%;
					// display: flex;
					.task-text {
						display: flex;
						justify-content: space-between;
						margin-left: 12px;
						height: 21px;

						.task-name {
							color: $laranja;
							font-weight: bolder;
							font-size: 14px;
						}

						.task-data {
							font-size: 10px;
							margin-top: 3px;
							color: $cinza-dark;
						}
					}

					.task-sinopse {
						font-size: 12px;
						margin-left: 12px;
						color: $cinza-dark;
					}

					.task-nivel {
						margin-left: 12px;
						display: flex;
					}
					.task-nivel-circulo {
						width: 12px;
						height: 12px;
						background: #e2e2e2;
						margin-right: 7px;
						border-radius: 100%;
						margin-bottom: 5px;
						&.completado {
							background: #f58420;
						}
					}
				}
			}

			.progressBar {
				margin-bottom: 0px;
			}
		}
	}
`;

export const ContainerRight = styled.div`
	.img-banner{
		border-radius: 7px;
		width: 800px;
		height: 320px;
	}
	.banner-slide-desk{
		@media (max-width: 992px) {
			display: none;
		}
		display: inline;
	}
	
	@media (max-width: 992px) {
			padding: 20px;
		}
	.topnews{
		h3{
			color: #000;
		}
		p{
			span{
				color: #15151F;
			}
		}
		@media (max-width: 992px) {
			flex-direction: column;
			.supertop-bf{
				width: 100%;
			}
			#topnews{
				width: 100%;
        		}			
   		}
	}
	
`;
export const Slider2 = styled.div`
	
	
	@media (min-width: 768px) {

		.slider-img{
			min-height: 371px;
		}
	}
	
`;
export const Slider3 = styled.div`
	
	
	@media (min-width: 768px) {

		.slider-img{
			min-height: 371px;
		}
	}
	
`;

export const SuperTops = styled.div`
	background: #fff;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-top: 28px;
	padding-right: 30px;
	width: 100%;
	height: 196px;
	@media (max-width: 768px) {
		padding-left: 10px;
		padding-right: 10px;
		height: auto;
	}
	.posicao-premiacao {
		position: absolute;
		margin-left: 10px;
		margin-top: 10px;
		width: 20px;
		height: 20px;
		background: #9ccb3d;
		border-radius: 100%;
		text-align: center;
		font-size: 16px;
		color: #fff;
		font-weight: bold;
		left: 1px;
	}

	.img-supertops {
		border-radius: 3px 0 0 3px;
		width: 70px;
		height: 73px;
		@media (max-width: 768px) {
			min-height: 59px;
		}
	}
	@media (max-width: 576px) {
	}
	h3 {
		font-size: 20px;
	}
	p {
		span {
			font-size: 14px;
		}
	}

	.container-supertop {
		.slick-next::before {
			color: rgb(239, 78, 35);
		}
		.slick-prev::before {
			color: rgb(239, 78, 35);
		}
		.slick-list{
			margin: 0 -7px;
		}
		
		.slick-slide>div{
			margin: 0 7px;
		}
		@media (max-width: 768px) {
			/* overflow-y: auto; */
			flex-direction: column;
			justify-content: center;
			gap: 10px;
			.slick-track>div{
			/* width: 155px!important; */
		}
		}
	}

	.supertop-item {
		position: relative;
		/* background: #efeeed; */
		border: 1px solid #e2e2e2;
		/* height: 158px; */
		/* margin: 0 7px; */
		/* width: 128px!important; */
		padding: 10px;
		font-size: 14px!important;
		@media (max-width: 576px) {
			/* width: 240px; */
			/* margin-right: 10px; */
			height: 96px;
			gap: 10px;
		}
		@media (max-width: 1500px) {
			/* width: 110px!important; */
		}
		.codigo-super-top {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-right: 1rem;
    		padding-left: 1rem;
			margin-top: 10px;
			@media (max-width: 768px) {
				padding: 0!important;
				font-size: 14px!important;
				margin-left: 10px;
			}
			@media (max-width: 1500px) {
				
				h2{
					font-size: 14px!important;
				}
			}	
			h2 {
				font-size: 15px;
				color: #313131;
				@media (max-width: 768px) {
					font-size: 9px;
				}
			}

			.super-top-pontos {
				font-weight: bold;
				font-size: 14px;
				color: #f58420;
				@media (max-width: 768px) {
					font-size: 14px;
				}
				/* @media (max-width: 1700px) {
					font-size: 10px;
				} */
			}
		}
		img {
			@media (max-width: 768px) {
				width: 60px;
			}
		}
	}
	.slick-initialized{
		padding: 0 5px!important;
	}
	
`;
export const ProdutosPremium = styled.div`
	background: #fff;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-top: 28px;
	padding-right: 30px;
		@media (max-width: 768px) {
			padding-left: 10px;
			/* padding-right: 10px; */
			padding-bottom: 70px;
		}

	.posicao-premiacao {
		position: absolute;
		margin-left: 10px;
		margin-top: 10px;
		width: 20px;
		height: 20px;
		background: #9ccb3d;
		border-radius: 100%;
		text-align: center;
		font-size: 16px;
		color: #fff;
		font-weight: bold;
	}
	/* .slick-slide{
		display: flex;
    	flex-direction: column;

	} */
	.img-supertops {
		border-radius: 3px 0 0 3px;
		width: 80px;
		max-height: 79px;
		min-height: 79px;
		@media (max-width: 768px) {
			min-height: 59px;
		}
	}
	@media (max-width: 576px) {
	}
	h3 {
		font-size: 20px;
	}
	p {
		span {
			font-size: 14px;
		}
	}

	.container-supertop {
		@media (max-width: 768px) {
			overflow-y: auto;
		}
	}

	.supertop-item {
		position: relative;
		background: #efeeed;
		border: 1px solid #e2e2e2;
		height: 82px;
		width: 205px!important;
		margin: 0 10px;
		@media (max-width: 576px) {
			width: 140px!important;
			margin-right: 10px;
			height: 72px;
		}
		
		.codigo-super-top {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-right: 1.5rem;
    		padding-left: 1.5rem;
			@media (max-width: 768px) {
				padding: 0!important;
			}	
			h2 {
				font-size: 15px;
				color: #313131;
				@media (max-width: 768px) {
					font-size: 9px;
				}
			}

			.super-top-pontos {
				font-weight: bold;
				font-size: 14px;
				color: #f58420;
				@media (max-width: 768px) {
					font-size: 7px;
				}
			}
		}
		img {
			@media (max-width: 768px) {
				width: 60px;
				height: 71px;
			}
		}
	}
	.slick-slide{
		justify-content: center!important;
	}
	.slick-initialized{
		/* padding: 0 20px!important; */
	}
	.slick-dots{
		@media (max-width: 768px) {
			bottom: -54px!important;
			}
		
	}
`;
export const ProdutosParticipantes = styled.div`
    /* padding-bottom: 63px!important; */
	.slick-prev:before,
	.slick-next:before {
		color: #ccc;
		@media (min-width: 800px) {
			font-size: 40px;
		}
	}
	.slick-next{
		@media (min-width: 800px) {
			right: -32px;
			font-size: 40px;
		}
	}
	.slick-prev{
		@media (min-width: 800px) {
			left: -50px;
		}
	}
	background: #fff;

	.pontos-participantes {
		font-weight: bold;
		font-size: 14px;
		color: #f58420;
		text-align: center;
		align-self: end;
	}
	.titulo-slides {
		@media (min-width: 800px) {
				margin-bottom: 28px;
				margin-top: 10px;
			}
		h3 {
			font-size: 20px;
			color: #15151F;
			
			@media (min-width: 800px) {
				margin-left: -33px;
			}
		}
		p {
			font-size: 14px;
		}
	}
	.box-categoria-participante {
		border: 1px solid #e2e2e2;
		border-radius: 5px;
		/* margin: 0 20px!important; */
		padding-top: 20px;
		min-height: 190px;
		display: grid;
		width: 148px;
    	height: 188px;
	}
	.img-categoria-premios {
		height: 57px;
	}
	.nome {
		font-size: 14px;
		width: 100%;
		color: #313131;
		text-align: center;
		text-transform: capitalize;
		color: #000;
		font-weight: bold;
	}
	.container-produtos-participantes{
		/* padding: 10px; */
		padding: 20px 64px 37px;
		@media (max-width: 992px) {
			padding: 20px 15px 37px;
		}
		.slick-slide{
			/* margin: 0 10px; */
		}
		.slick-list{
			height: 200px;		
			@media (min-width: 992px) {
				margin-right: -17px;
			}
		}
		 .slick-prev::before,.slick-next::before {
			color: #ef4e23;
			width: 33px!important;
		 }
	}
`;
export const BodyModal = styled.div`
	background-color: #58595b;
	height: 100vh;

	.info-telefone {
		font-size: 14px;
		color: #fff;
	}
	.body-card {
		@media (min-width: 992px) {
			width: 28rem;
		}
	}
	.icon-size {
		font-size: 4rem;
	}
`;





export const Notificacao = styled.div`
	
	.header-notificacoes{
		background: var(--laranja);
		color: white;
		height: 7vh;
		display: flex;
		align-items: center;
		padding-left: 2vw;
		border-top-right-radius: 4px!important;
		border-top-left-radius: 4px!important;
	}

	.conteudo-notificacao{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		display: block;
		}

	.ativo{
		background: #fff;
		overflow-y: scroll;
		border-top-right-radius: 10px!important;
		border-top-left-radius: 5px!important;
		z-index: 11!important;
	}

	.close-button {
		margin-right: 8px;
		text-decoration: none;
		transition: all 0.5s ease;
		position: absolute;
		padding: 1.5px 7px;
		right: 0;
		margin-left: -10px;
		margin-top: 0px;
		border-radius: 50%;
		border: 2px solid #fff;
		color: white;
		-webkit-box-shadow: -4px -2px 6px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: -4px -2px 6px 0px rgba(0,0,0,0.1);
		box-shadow: -3px 1px 6px 0px rgba(0,0,0,0.1);
	}
	.oculto{
		display: none;
	 }
	.close-button:hover {
		background-color: tomato;
		color: #fff;
	}
	.card-notificacao{
		margin: 13px;
		background: #cdcdcd;
		border-radius: 3px;
		padding: 9px;
	}
	.btn-marcar-lida{
		cursor: pointer;
		text-align: right;
	}
`;

export const TopNews = styled.div`

	width:100%;
	height: 222px;
	margin-left: auto;
	margin-bottom: 91px;
	margin-top: 32px;
	border-radius: 5px;
	position: relative;
	
	.container-slide{
		display: flex;
		justify-content: space-between;
		.item-post{
			width: 248px;
			height: 220px;
			position: relative;
			img{
				width: 100%;
				height: 100%;
				border-radius: 7px;
			}
			@media (max-width: 800px) {
			height: 275px;
		}
		}
		
	}
			.mobile{
						display: none!important;
					}
	.container-title{
		h3{
			font-size: 20px;
			margin-bottom: 25px;
			color: #000;
			@media (max-width: 800px) {
			    padding-left: 20px;
			}
		}
		
		a{
			text-decoration: none;
			color: #000;
			padding-right: 27px;
			font-size: 16px;
			::before{
			content: '';
			position: absolute;
			right: 0px;
			top: 4px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yLjcxOTkxIDEwLjAzMzdMNy4wNjY1NyA1LjY4NzAzQzcuNTc5OTEgNS4xNzM2OSA4LjQxOTkxIDUuMTczNjkgOC45MzMyNCA1LjY4NzAzTDEzLjI3OTkgMTAuMDMzNyIgc3Ryb2tlPSIjMTUxNTFGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8L3N2Zz4NCg==);
			background-size: contain;
			width: 16px;
			height: 16px;
			transform: rotate(90deg);
		}
		}
	}
	
	@media (max-width: 800px) {
			width: 100%;
			height: 100%;
			.container-slide{
				display: none;
				justify-content: center;
				flex-direction: column;
				
			}
			.mobile{
				display: flex!important;
				flex-direction: row;
			}
		}
	.carousel-item{
		img{
			height: 222px!important;
			width: 100%!important;
		}
		@media (max-width: 800px) {
			img{
			height: 270px!important;
		}
			}
	}
	.link-topnews{
		text-align: end;
		/* color: #fff; //bf mudar */
		a{
			/* text-decoration: none; */
			color: #000;//bf mudar
		}
	}
	.overlay-0,.overlay-1,.overlay-2 {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;	
		opacity: 0;
		transition: .5s ease;
		background: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		/* width: 287px; */
    	/* height: 222px; */
		z-index: 2;
		@media (max-width: 950px) {
			height: 100%!important;
			width: 100%!important;
	}
	.overlay-text {
		color: white;
		font-size: 20px; /* ou o tamanho desejado */
		visibility: hidden;
		transition: .5s ease;
	}
	
}
				
.overlay-0{
:hover  {
	opacity: 0.8;
}
}
.overlay-1{
:hover  {
	opacity: 0.8;
}
}
.overlay-2{
:hover  {
	opacity: 0.8;
}
}
:hover .overlay-text {
	visibility: visible;
}

`
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Col, Row, Card, Form, Container, Button, Fade } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import images from "../../images/images";
import Logo from "../../images/img/logo.svg";
import LogoDesk from "../../images/img/logo-bf.svg";
import IconAviso from "../../images/img/icon-aviso.svg";
import IconClock from "../../images/img/icon-clock.svg";
import {
  ContainerLeft,
  ContainerRight,
  CardLogin,
  ContainerLeftMobile,
  ContainerRightMobile,
  BodyModal,
  BackGround,
  LoginMobileComponent
} from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import {
  loginAction,
  loginSelector,
  getLoginReset,
} from "../../slices/loginSlice";
import {
  primeiroAcessoAction,
  primeiroAcessoSelector,
  primeiroAcessoReset,
} from "../../slices/primeiroAcessoSlice";
import axios from "axios";
// import { NeveEfeito } from "../../components/NeveEfeito"

const sitekey = "6Ld482QbAAAAAM8iW9B-FKXtMj8Hde-TTzj62-PP";

export function LoginPage() {
  let location = useLocation();
  let navigate = useNavigate();

  const [loginData, setLoginData] = useState();
  const [loginMobile, setLoginMobile] = useState(true);
  const [primeiroAcesso, setPrimeiroAcesso] = useState(false);
  const [modalAviso, setModalAviso] = useState(false);
  const [modalErro, setModalErro] = useState(false);
  const [modalMensagem, setModalMensagem] = useState("");
  const [backgroundWhite, setBackgroundWhite] = useState("d-block");
  //   const [cpfPrimeiroAcesso, setCpfPrimeiroAcesso] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);
  const [limparStore, setLimparStore] = useState(true);
  const [modalModeracao, setModalModeracao] = useState(false);

  const [changeEye, setChangeEye] = useState("bi bi-eye-slash pass-hide");

  const togglePassword = () => {
    passwordShown
      ? setChangeEye("bi bi-eye-slash pass-hide")
      : setChangeEye("bi bi-eye pass-hide");
    setPasswordShown(!passwordShown);
  };

  const [showForm, setShowForm] = useState("p-0 d-none d-lg-block");
  const dispatch = useDispatch();

  const { loginState, loading, hasErrors, errorMessage } =
    useSelector(loginSelector);
  const {
    primeiroAcessoState,
    primeiroAcessoLoading,
    primeiroAcessoErrors,
    primeiroAcessoMessage,
  } = useSelector(primeiroAcessoSelector);

  const loginStorage = sessionStorage.getItem("token");
  useEffect(() => {
    if (loginStorage) {
      navigate("/home");
    }
    if (limparStore) {
      dispatch(primeiroAcessoReset());
      dispatch(getLoginReset());
      setLimparStore(false);
    }
  }, [limparStore, dispatch, navigate, loginStorage]);

  useEffect(() => {
    let modalTime;

    if (loginState) {
      dispatch(getLoginReset());
      if (loginState === "MODERACAO_DE_DOCUMENTO") {
        setModalModeracao(true);
      } else {
        window.location.href = '/home'
      }


    }
    if (hasErrors) {
      setModalErro(true);
      setModalMensagem(errorMessage);
      dispatch(getLoginReset());
      modalTime = setTimeout(() => {
        setModalErro(false);
      }, 5000);
    } else {
      clearTimeout(modalTime);
    }
  }, [
    loginState,
    loading,
    hasErrors,
    errorMessage,
    dispatch,
    location,
    navigate,
  ]);

  useEffect(() => {
    let modalTimePrimeiroAcesso;
    if (primeiroAcessoState && !limparStore) {
      navigate("/cadastro");
    }
    if (primeiroAcessoErrors && !limparStore) {
      setModalAviso(true);
      //setModalErro(true);
      //setModalMensagem(primeiroAcessoMessage);
      modalTimePrimeiroAcesso = setTimeout(() => {
        setModalErro(false);
      }, 5000);
    } else {
      clearTimeout(modalTimePrimeiroAcesso);
    }
  }, [
    primeiroAcessoState,
    primeiroAcessoLoading,
    primeiroAcessoErrors,
    primeiroAcessoMessage,
    navigate,
    limparStore,
  ]);

  useEffect(() => {
    if (loginData) {
      axios
        .get("https://api.ipify.org?format=json")
        .then((result) => {
          loginData.ip = result.data.ip;
          dispatch(loginAction(loginData));
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [loginData, dispatch]);

  function setMobileLogin() {
    setLoginMobile(false);
    setShowForm("p-0 d-block d-lg-block");
    setBackgroundWhite("d-none");
  }

  function setAddAcesso() {
    setPrimeiroAcesso(true);
  }
  function setAddAcessoMobile() {
    setMobileLogin();
    setPrimeiroAcesso(true);
  }
  function setRemoveAcesso() {
    setPrimeiroAcesso(false);
  }

  function closeModalAviso() {
    setModalAviso(false);
  }

  const handleLogin = (values) => {
    console.log("valores", values);
    sessionStorage.removeItem("acessoEspelho");
    setLoginData(values);
  };
  const handleLoginPrimeiroAcesso = (values) => {
    console.log("valores", values);
    sessionStorage.removeItem("acessoEspelho");
    dispatch(primeiroAcessoAction(values));
  };

  const validationSchema = yup.object().shape({
    cpf: yup
      .string()
      .min(11, "CPF tem 11 digitos")
      .max(11, "CPF tem 11 digitos")
      .matches(/^[0-9]{11}$/, "somente números")
      .required("CPF é obrigatório"),
    senha: yup
      .string()
      .min(8, "Senha deve ter pelo menos 8 caracteres")
      .matches(/^(?=.*?[A-Z])(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/, {
        message: "Senha inválida",
        excludeEmptyString: true,
      })
      .required("Senha é obrigatório"),
    recaptcha: yup.string().required(),
  });
  const validationSchemaCpf = yup.object().shape({
    cpf: yup
      .string()
      .min(11, "CPF tem 11 digitos")
      .max(11, "CPF tem 11 digitos")
      .matches(/^[0-9]{11}$/, "somente números")
      .required("CPF é obrigatório"),
    recaptcha: yup.string().required(),
  });

  return (
    <div className="login-tema">
      <Row>
        <Col className="d-flex justify-content-center">
          <Fade in={modalErro}>
            <Card
              className="text-center p-3 m-2 z-index-top position-absolute"
              bg="danger"
              text="white"
            >
              <Card.Body>
                <Card.Title as="h2">Erro</Card.Title>
                <Card.Text>{modalMensagem}</Card.Text>
              </Card.Body>
            </Card>
          </Fade>
        </Col>
      </Row>
      {modalModeracao && (
        <BodyModal className="d-flex justify-content-center align-items-center">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center">
                <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                  <Card.Body className="body-card">
                    <Card.Title className="text-center" as="h5">
                      Cadastro em processo de validação
                    </Card.Title>
                    <Card.Text className="text-center icon-size">
                      <img src={IconClock} alt="" />
                    </Card.Text>
                    <Card.Text className="text-center">
                      O seu documento ainda está em análise. Em breve você
                      receberá um SMS de confirmação assim que for liberado.
                    </Card.Text>

                    <Button
                      className="btn-laranja w-100 mt-3"
                      onClick={() => { setModalModeracao(false) }}
                    >
                      Sair
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3 text-center">
              <Col>
                <p className="info-telefone">
                  Dúvidas ? Ligue para 0800 780 0606 e<br /> fale com um de
                  nossos atendentes.
                </p>
              </Col>
            </Row>
          </Container>
        </BodyModal>
      )}
      {!modalAviso && !modalModeracao && (
        <Container fluid className="p-0">
          <Row className="m-0">
            <Col className="p-0 d-none d-lg-block" lg={4}>
              <ContainerLeft className="d-flex align-items-center flex-column">
                <div className="container-logo a">
                  <img src={Logo} alt="" className="logo-mais-top d-none" />
                  <img src={LogoDesk} alt="" className="logo-mais-top" style={{ width: "185px" }} />
                </div>
                {primeiroAcesso && (
                  <div className="container-tomas">
                    +{" "}
                    <img
                      src={images.LoginImgPrimeiroAcesso}
                      className="tom-mais-top primeiro-acesso"
                      alt=""
                    />
                    +{" "}
                  </div>
                )}
                {!primeiroAcesso && (
                  <div className="container-tomas">
                    <img
                      // src={images.LoginImg}
                      // src={images.TomasBlackFridayLogin}
                      src={images.LoginImgNatal}
                      className="tom-mais-top"
                      alt=""
                    />
                    -{" "}
                  </div>
                )}
              </ContainerLeft>
            </Col>

            {loginMobile && (
              <LoginMobileComponent className="p-0">
                <Row className="m-0 p-0 d-flex d-lg-none">
                  <Col className="p-0">
                    <ContainerLeftMobile className="d-flex align-items-center flex-column">
                      <img src={Logo} alt="" className="logo-mais-top d-none" />
                      {/* <img src={LogoDesk} alt="" className="logo-mais-top" /> */}
                      <div className="container-logo">
                        <img
                          src={images.LoginTomasPequeno}
                          alt=""
                          className="tomas-top"
                        />
                      </div>
                    </ContainerLeftMobile>
                  </Col>
                </Row>
                <Row className="m-0 p-0 d-flex d-lg-none login-mobile">
                  <Col className="p-0">
                    <ContainerRightMobile className="align-items-center d-flex flex-column bg-white">
                      <h2 className="mb-4">Bem vindo ao +top</h2>
                      <Button
                        variant="secondary"
                        className="col-9 btn-laranja"
                        onClick={setMobileLogin}
                      >
                        Ir para o login
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className="col-9 mt-3 btn-laranja-outline"
                        onClick={setAddAcessoMobile}
                      >
                        Primeiro acesso
                      </Button>
                    </ContainerRightMobile>
                  </Col>
                </Row>
              </LoginMobileComponent>
            )}

            <Col className={showForm} lg={8}>
              <Row className="m-0 d-flex d-lg-none">
                <Col>
                  <div className="d-flex justify-content-center mt-4 position-relative">
                    <img src={Logo} alt="" className="logo-mais-top logo-tema-natal" />
                    <img src={images.EnfeiteLoginMobile} alt="" className="enfeite-mobile-natal" />
                  </div>
                </Col>
              </Row>
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  <ContainerRight className="align-items-center position relative">
                    <img src={images.BgNatalMobileLogin} alt="" className="bg-mobile-natal" />
                    <CardLogin>
                      {!primeiroAcesso && (
                        <Card className="card-login border-0 align-items-center position-relative">
                          <Card.Body>
                            <img className="enfeite-bolas-natal" src={images.EnfeiteBolasNatal} alt="" />
                            <Card.Title className="text-center titulo-card">
                              Faça seu login
                            </Card.Title>
                            <Formik
                              initialValues={{
                                cpf: "",
                                senha: "",
                                recaptcha: "",
                              }}
                              onSubmit={(values) => handleLogin(values)}
                              validationSchema={validationSchema}
                            >
                              {({
                                values,
                                handleChange,
                                errors,
                                isValid,
                                handleSubmit,
                                setFieldValue,
                                dirty,
                              }) => (
                                <Form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  className="col-12"
                                >
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      CPF
                                    </Form.Label>

                                    <Form.Control
                                      type="text"
                                      maxLength="11"
                                      name="cpf"
                                      value={values.cpf}
                                      onChange={handleChange}
                                      isInvalid={!!errors.cpf}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.cpf}
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group className="mb-3 position-relative">
                                    <Form.Label className="label-input">
                                      Senha
                                    </Form.Label>
                                    <Form.Control
                                      type={passwordShown ? "text" : "password"}
                                      name="senha"
                                      value={values.senha}
                                      onChange={handleChange}
                                      isInvalid={!!errors.senha}
                                    />
                                    <i
                                      className={changeEye}
                                      onClick={togglePassword}
                                    ></i>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.senha}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <div className="d-flex justify-content-end checkbox-senha">
                                    <div>
                                      <Link
                                        className="link-secondary"
                                        to="/esqueceu-senha"
                                      >
                                        Esqueceu sua senha ?
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-center mt-4">
                                    <ReCAPTCHA
                                      sitekey={sitekey}
                                      onChange={(e) => {
                                        setFieldValue("recaptcha", e);
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      type="submit"
                                      disabled={!isValid || !dirty}
                                      variant="secondary col"
                                      className="mt-4 btn-laranja"
                                    >
                                      Entrar
                                    </Button>
                                  </div>
                                  <Card.Text className="possui-cadastro">
                                    Ainda não possui cadastro ?
                                    <Link
                                      to="#"
                                      className="link-secondary"
                                      onClick={setAddAcesso}
                                    >
                                      {" "}
                                      Clique aqui
                                    </Link>
                                  </Card.Text>
                                </Form>
                              )}
                            </Formik>
                          </Card.Body>
                        </Card>
                      )}
                      {primeiroAcesso && (
                        <Card className="card-login border-0 align-items-center">
                          <Card.Body className="width-car-primeiro">
                            <Card.Title className="text-center titulo-card">
                              Primeiro Acesso
                            </Card.Title>
                            <div className="d-flex justify-content-center mb-3">
                              <Card.Text className="text-center">
                                Seja bem-vindo ao +top, venda, pontue e ganhe
                                prêmios.
                              </Card.Text>
                            </div>

                            <Formik
                              initialValues={{ cpf: "" }}
                              onSubmit={(values) =>
                                handleLoginPrimeiroAcesso(values)
                              }
                              validationSchema={validationSchemaCpf}
                            >
                              {({
                                values,
                                handleChange,
                                errors,
                                isValid,
                                handleSubmit,
                                setFieldValue,
                                dirty,
                              }) => (
                                <Form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  className="col-12"
                                >
                                  <Form.Group className="mb-3">
                                    <Form.Label className="label-input">
                                      CPF
                                    </Form.Label>

                                    <Form.Control
                                      maxLength="11"
                                      type="text"
                                      name="cpf"
                                      value={values.cpf}
                                      onChange={handleChange}
                                      isInvalid={!!errors.cpf}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.cpf}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <div className="d-flex justify-content-center mt-4">
                                    <ReCAPTCHA
                                      sitekey={sitekey}
                                      onChange={(e) => {
                                        setFieldValue("recaptcha", e);
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      type="submit"
                                      disabled={!isValid || !dirty}
                                      variant="secondary col"
                                      className="mt-4 btn-laranja"
                                    >
                                      Enviar
                                    </Button>
                                  </div>
                                  <p className="possui-cadastro">
                                    Já possui cadastro ?
                                    <Link
                                      className="link-secondary"
                                      to="#"
                                      onClick={setRemoveAcesso}
                                    >
                                      {" "}
                                      Entrar
                                    </Link>
                                  </p>
                                </Form>
                              )}
                            </Formik>
                          </Card.Body>
                        </Card>
                      )}

                      <div className="d-flex justify-content-center mt-3">
                        <div className="info-telefone text-center">
                          <p className="my-0 strong">Central de Atendimento +top</p>
                          <h5 className="my-0">0800 780 0606</h5>
                          <p className="my-1 font-weight-light ">2ª a 6ª Feira (exceto feriados)</p>
                          <p className="my-1 strong">Atendimento Telefônico das 10h às 16h | WhatsApp das 9h às 18h</p>
                          <p className="my-0">atendimento@programamaistop.com.br</p>
                        </div>
                      </div>
                    </CardLogin>
                  </ContainerRight>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}

      {modalAviso && (
        <BodyModal className="d-flex justify-content-center align-items-center">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center">
                <Card className="d-flex justify-content-center align-items-center shadow bd-radius p-5">
                  <Card.Body className="body-card">
                    <Card.Text className="text-center icon-size">
                      <img src={IconAviso} alt="" />
                    </Card.Text>
                    <Card.Title className="text-center" as="h5">
                      Ops, algo deu errado
                    </Card.Title>
                    <Card.Text className="text-center">
                      Entre em contato com a nossa Central<br /> através do
                      telefone: <div className="fw-bold">0800 780 0606</div>
                    </Card.Text>

                    <Link
                      onClick={closeModalAviso}
                      className="btn btn-secondary btn-laranja w-100 mt-3"
                      to="#"
                    >
                      Fechar
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3 text-center">
              <Col>
                <p className="info-telefone">
                  Dúvidas ? Ligue para 0800 780 0606 e<br /> fale com um de
                  nossos atendentes.
                </p>
              </Col>
            </Row>
          </Container>
        </BodyModal>
      )}
      <BackGround className={backgroundWhite}>
        {/* <img src={images.BackgroundLogin} alt="" className="bg-desktop" /> */}
        <img src={images.BackgroundBf} alt="" className="bg-desktop" />
        <img src={images.BackgroundBfMob} alt="" className="bg-mobile-natal" />

       
      </BackGround>
      {/*process.env.REACT_APP_MODE !== 'development' && process.env.REACT_APP_MODE !== 'staging' && 
      <TampaoStyle>
        <img src={images.ImgTampao} alt="" className="img-desk" />
        <img src={images.ImgTampaoMobile} alt="" className="img-mobile" />
      </TampaoStyle>*/}
      {/* <NeveEfeito /> */}
    </div>

  );
}
